<template>
  <div style="">
    <portal to="title-page">
      <h1>Checkin Reservacion : {{reservation ? reservation.code : 'Por favor espere'}}</h1>
    </portal>
    <Loading :active="loading"/>
    
    <v-row v-if="reservation">
      <v-col sm="12" md="12">
        <p v-if="!(ids.adults['a1'].data && reservation.paymentsCompleted)" class="error--text"><v-icon class="error--text">mdi-alert</v-icon> Complete la información para hacer checkin</p>
        <p v-if="!(reservation.paymentStatus == 'confirmed')" class="error--text"><v-icon class="error--text">mdi-alert</v-icon> Para hacer checkin es necesario que el status de pago la reserva este en <strong>confirmed</strong></p>
        <div class="d-flex align-items-center mb-2">
          <h3>Ingrese las ids de los clientes</h3>
          <v-btn
            color="info"
            class="ml-auto"
            small
            @click="checkin()"
            :disabled="!(ids.adults['a1'].data && reservation.paymentsCompleted && reservation.paymentStatus == 'confirmed')"
          >
            <v-icon>mdi-check-circle</v-icon> Hacer Checkin
          </v-btn>
          <v-btn
            color="primary"
            class="ml-2"
            small
            @click="$router.push({path:`/backend/taquilla/reservations/${reservation.id}/edit`})"
          >
            <v-icon>mdi-arrow-left</v-icon> Regresar
          </v-btn>
        </div>
        <v-card
          elevation="2"
        >
          <v-card-text>
            <v-row>
              <v-col md="8">
                <div>
                  <v-list
                    subheader
                    two-line
                  >
                    <!-- <v-subheader inset>Archivos</v-subheader> -->

                    <v-list-item
                      v-for="(i, index) in ids.adults"
                      :key="index"
                    >
                      <v-list-item-avatar>
                        <v-img v-if="i.data" :src="i.data.url"></v-img>
                        <v-icon
                          v-else
                          class="grey lighten-1"
                          dark
                        >
                          mdi-cloud-alert
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <!-- <v-list-item-title >Adulto {{ index.slice(1) }}</v-list-item-title> -->
                        <v-list-item-title >Archivo con las IDS</v-list-item-title>

                        <v-list-item-subtitle>
                          <a v-if="i.data" :href="i.data.url">Ver archivo</a>
                          <span v-else>Aun no se ha cargado un archivo</span>
                          <v-progress-linear v-if="ids.adults[index].uploadValue !== null" :value="ids.adults[index].uploadValue"></v-progress-linear>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-file-input
                          v-model="ids.adults[index].image"
                          label="Seleccione"
                          style="width: 200px"
                          :rules="[rules.required]"
                          accept="image/png, image/jpeg, image/bmp"
                          prepend-icon="mdi-paperclip"
                          @change="uploadImage(index, 'adult')"
                        ></v-file-input>
                        <!-- <v-btn icon>
                          <v-icon color="grey lighten-1">mdi-information</v-icon>
                        </v-btn> -->
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </div>
                
              </v-col>
              <v-col md="4"></v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <h3 class="mt-3">Complete la información de los pagos</h3>
        <Payments ref="payments" :reservation="reservation" :btnAdd="false"/>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import Vue from "vue";
  import Reservation from "@/models/Reservation";
  import Loading from "@/components/Loading";
  import Payments from "@/components/reservations/payments/Payments";
  Vue.use('Loading', Loading);
  //Vue.component('History', History);
  export default {
    name:'TaquillaReservationCheckin',
    components:{Payments},
    data() {
      return {
        reservationId:this.$route.params.id,
        reservationModel:new Reservation(),
        reservation:null,
        unsubscribe:null,
        ids:{
          adults: {
            'a1': {
              image: null,
              uploadValue: null,
              data: false,
            }
          },
          elderlies: {},
          children: {},
          infants: {},
        },
        loading:true,
        rules:{
          required: v => !!v || 'El campo es obligatorio',
          number : v => {
            const pattern = /^[0-9]+([.][0-9]+)?$/
            return pattern.test(v) || 'Cantidad invalida'
          },
        },
        snackbar:{
          show:false,
          color:'',
          message:''
        },
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'Taquilla')
    },
    mounted() {
      //this.ids.adults['a'+1] = {}
      this.unsubscribe = this.reservationModel.getById(this.reservationId).onSnapshot(snap => {
        this.reservation = snap.data()
        this.reservation.setPayments()
        this.loading = false;
      })
      this.reservationModel.getIdentifications(this.reservationId).onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          if (data.type == 'adult') {
            this.ids.adults[doc.id].data = data
          }
          /*if (data.type == 'elderly') {
            this.ids.elderlies[doc.id].data = data
          }
          if (data.type == 'child') {
            this.ids.children[doc.id].data = data
          }
          if (data.type == 'infant') {
            this.ids.infants[doc.id].data = data
          }**/
        });
      })
    },
    methods:{
      uploadImage(index, type) {
        let item
        if (type === 'adult') {
          item = this.ids.adults[index]
        }
        if (type === 'elderly') {
          item = this.ids.elderlies[index]
        }
        if (type === 'child') {
          item = this.ids.children[index]
        }
        if (type === 'infant') {
          item = this.ids.infants[index]
        }
        if (item.image) {
          let upload = this.reservationModel.uploadIdentification(this.reservation.id, item.image, index);
          upload.ref.on(`state_changed`, snapshot=> {
              item.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            },
            error => {
              console.log(error.message);
              this.showSnackbarError('Error al subir la imagen')
            },
            ()=>{
              item.uploadValue = 100;
              upload.ref.snapshot.ref.getDownloadURL().then((url)=>{
                const idInfo = {
                  fullpath: upload.image.fullpath,
                  name: upload.image.name,
                  url: url,
                  pax: type + index.slice(1),
                  type: type
                }
                this.reservationModel.addIdentification(this.reservation.id, index, idInfo)
                  .then(() => {
                    item.uploadValue = null
                    item.image = null;
                    this.showSnackbarSuccess('La imagen se subio correctamente')
                  })
                  .catch(error => {
                    console.log('error', error);
                    this.showSnackbarError('Error al agregar la imagen en la coleccion');
                  })
              });
            }
          );
        }
        //console.log('ids', this.ids.adults[index].image)
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'success';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'error';
      },
      async checkin() {
        this.loading = true
        await this.reservationModel.updateStatusToCheckin(this.reservation, {
          status: 'checkin',
        })
        this.loading = false
        this.$router.push({path:`/backend/taquilla/reservations/${this.reservation.id}/edit`})
      }
    },
    //computed: mapState(['dialogConfirmedInfo'])
  }
</script>